.footer_wrapper {
    padding-right: 0.5em;
    margin: 0;
    margin-bottom: 5px;
    font-size: 12px !important;
    padding-top: 10px;
    margin-top: auto;
    margin-left: auto;

    flex: 0 0 auto;
}

@media screen and (max-width: 500px) {
    .footer_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}
