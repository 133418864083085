.main-wrapper {
    flex: 1 0 auto;
}

.main-view-content {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 0 1%;
}

.form-wrapper {
    flex: 1;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    padding: 20px;

    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

.resizable {
    overflow: hidden;
    margin-left: 1%;
}

.form-wrapper > * {
    margin: 15px;
}

.permission_view {
    margin: auto;
}

@media only screen and (max-width: 500px) {
    .form-wrapper > * {
        margin: 5px;
    }
    .form-wrapper {
        padding: 10px;
        max-width: 95%;
    }
}

@media only screen and (min-width: 500px) {
    .form-wrapper {
        max-width: 95%;
    }
}

@media only screen and (min-width: 768px) {
    .form-wrapper {
        max-width: 85%;
    }
}

@media only screen and (min-width: 1000px) {
    .form-wrapper {
        max-width: 65%;
    }
}
@media only screen and (min-width: 1200px) {
    .form-wrapper {
        max-width: 55%;
    }
}
