.link_bar_wrapper {
    text-align: center;
    margin: 30px;
    display: inline;
}

.single_link {
    text-decoration: none;
    color: #66ccff;
    cursor: pointer;
    &:hover {
        color: #0280c0c3;
    }
}

@media only screen and (max-width: 500px) {
    .link_bar_wrapper {
        margin: 25px;
    }
}
